<template>
    <div>
        <Modal :title="title" v-model="isShowModal">
            <template v-slot:modal-content>
                <div class="alert-word">
                    {{ message }}
                </div>
            </template>
            <template v-slot:modal-bottom>
                <Button
                    buttonWord="取消"
                    buttonStyle="grey"
                    @click="turnOffModal"
                />
                <Button
                    buttonWord="確定"
                    buttonStyle="blue"
                    @click="openingCommission"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import { openingCommissionAPI } from '@/assets/javascripts/api'

export default {
    name: 'AdminOpeningAlert',
    emits: ['update:modelValue', 'update:commissionStatus', 'update:success'],
    components: {
        Modal,
        Button
    },
    props: {
        modelValue: {
            type: Boolean
        },
        success: {
            type: Boolean
        },
        mType: {
            type: String
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        },
        openingCommission: async function () {
            this.$setGaEvent('commissionOpen', 'click-Button')
            this.$showLoading()
            try {
                await openingCommissionAPI(this.mType)
                this.commissionStatusValue = 'open'
                this.$emit('update:success', true)
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.$showErrorMessage(this.errorMessage)
            } finally {
                this.$hideLoading()
                this.turnOffModal()
                window.scrollTo(0, 0)
            }
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        commissionStatusValue: {
            get() {
                return this.commissionStatus
            },
            set(val) {
                this.$emit('update:commissionStatus', val)
            }
        }
    },
    data() {
        return {
            title: '確定要返回每日更新階段嗎？',
            message: '請注意，一旦返回每日更新階段後，將放棄本次試跑之報表。',
            errorMessage: '切換成每日更新階段時出現錯誤..建議您重新整理頁面'
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-word {
    font-size: 14px;
    color: $secondary-grey;
}
</style>
